import React, { useEffect, useState } from "react";
import history from "../history";
import Dropdown from "openstack-uicore-foundation/lib/components/inputs/dropdown";
import { connect } from "react-redux";
import { clearSummit, loadSummits, setSummit } from "../actions/base-actions";
import T from "i18n-react/dist/i18n-react";

const SelectSummitPage = ({ summit, isLoggedUser, accessTokenQS, summits, loadSummits, setSummit, clearSummit }) => {
    const [includePastSummits, setIncludePastSummits] = useState(false);
    const value = summit ? summit.id : null;

    useEffect(() => {
        clearSummit();
        if (isLoggedUser || accessTokenQS) {
            loadSummits(includePastSummits);
        }
    }, [isLoggedUser, accessTokenQS, includePastSummits]);

    const onSelectSummit = (ev) => {
        const summitId = ev.target.value;
        const summit = summits.find(s => s.id === summitId);
        setSummit(summit);
        history.push(`/check-in/${summit.slug}`);
    };

    const handleCheckboxChange = (ev) => {
        setIncludePastSummits(ev.target.checked);
    };

    const summits_ddl = summits.map(s => ({ label: s.name, value: s.id }));

    return (
      <div className="container summit-select-page">
          <div className="row">
              <div className="col-md-12">
                  <p>{T.translate("general.select_label")}:</p>
                  <Dropdown
                    id="summit"
                    value={value}
                    placeholder={T.translate("general.select_show")}
                    options={summits_ddl}
                    onChange={onSelectSummit}
                  />
              </div>
              <div className="col-md-12 form-check abc-checkbox">
                  <input
                    type="checkbox"
                    id="all_summits"
                    checked={includePastSummits}
                    onChange={handleCheckboxChange}
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="all_summits">
                      {T.translate("general.show_all_summits")}
                  </label>
              </div>
          </div>
      </div>
    );
};

const mapStateToProps = ({ baseState, loggedUserState }) => ({
    isLoggedUser: loggedUserState.isLoggedUser,
    ...baseState
});

export default connect(mapStateToProps, {
    loadSummits,
    setSummit,
    clearSummit,
})(SelectSummitPage);
