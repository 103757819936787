export const setCSSVars = (data) => {
    if (typeof document !== 'undefined') {
        data.forEach(setting => {
            if (getComputedStyle(document.documentElement).getPropertyValue(`--${setting.key}`)) {
                document.documentElement.style.setProperty(`--${setting.key}`, setting.value);
                document.documentElement.style.setProperty(`--${setting.key}50`, `${setting.value}50`);
            }
        });
    }
};

export const range = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const getUnixTimestamp = (date) => Math.floor(date.getTime() / 1000);
